import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    createFilterOptions
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import { format, isValid, parse } from 'date-fns';
import { AsYouType } from 'libphonenumber-js';
import { find, get, isNil } from 'lodash';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import React, { useContext, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { search } from 'sr-types';
import { Money } from 'sr-types/lib/production/v1/graphql';
import LexicaljsEditor from '../../lab/form/lexical/LexicaljsEditor';
import RoundAvatar from '../../production/header/RoundAvatar';
import { KeyValueMap } from '../KeyValueMap';
import { H, V } from '../Layout';
import { useStyling } from '../Theme';
import { UserContext } from '../auth/UserContext';
import LocationsInput from '../components/LocationsInput';
import MetadataAutocomplete from '../components/MetadataAutocomplete';
import ReferenceAutocomplete from '../components/ReferenceAutocomplete';
import SectionTitle from '../components/SectionTitle';
import TimeInput from '../components/TimeInput';
import TimezoneInput from '../components/TimezoneInput';
import { constants } from '../constants';
import I18n from '../i18n/I18n';
import { getBrowserLocale, getL10nDef } from '../i18n/localization';
import { API_DATE_FORMAT, DISPLAY_TIME_PLACEHOLDER } from '../utils/dateTime';
import { NANOS, getCurrencyConfig } from '../utils/money';
import { formatNumber } from '../utils/numbers';
import { FormContext, resolve } from './FormContext';
import { ReferenceOption, isReferenceOptionEqualToValue } from './forms';
import ChipOptionsRenderer from './widgets/ChipOptionsRenderer';
import Dropzone from './widgets/Dropzone';
import ImageWidget from './widgets/ImageWidget';

const setValue = (handleChange, coerceChangeToArray: boolean, mapTo: string) => {
    return (prop, value) => {
        handleChange(prop, value, coerceChangeToArray, mapTo);
    };
};

interface FormWidgetProps {
    component?: string;
    Icon?: React.ReactNode;
    disabled?: boolean;
    readOnly?: boolean;
    hasErrors?: boolean;
    errors?: any;
    onChange?: any;
    coerceChangeToArray?: boolean;
    hideHelperText?: boolean;
    mapTo?: string;
    editInputValue?: boolean;
    toggleOpen?: (open: boolean) => void;
    isAddEnabled?: boolean;
    isCustomEelementOpen?: boolean;
    [key: string]: any;
}

export default React.forwardRef(
    (
        {
            component = 'Input',
            Icon = undefined,
            disabled = false,
            readOnly = false,
            hasErrors = false,
            errors = undefined,
            onChange = undefined,
            coerceChangeToArray = false,
            hideHelperText = false,
            mapTo = undefined,
            editInputValue = false,
            toggleOpen = undefined,
            isAddEnabled = false,
            isCustomEelementOpen = false,
            ...rest
        }: FormWidgetProps,
        ref
    ) => {
        const { isDarkMode, isMobile } = useStyling();
        const { activeOrganizationAccount } = useContext(UserContext);
        const variant = (rest.variant as const) || constants.defaultWidgetVariant;
        const size = rest.size || constants.defaultWidgetSize;

        // If we are not receiving onChange we are using FormContext.
        const { validation, state, handleChange, setState } = useContext(FormContext);
        const usingFormContext = typeof onChange === 'undefined';
        const value = usingFormContext ? resolve(state, rest.name, mapTo) : rest.value;
        const commit = usingFormContext ? setValue(handleChange, coerceChangeToArray, mapTo) : onChange;
        const resolvedErrors = usingFormContext && validation ? get(validation.errors, rest.name) : errors;
        const required = !disabled && (usingFormContext && validation ? !!get(validation.required, rest.name) : false);
        const isError = hasErrors || (resolvedErrors && resolvedErrors.length > 0);
        const errorText =
            (Array.isArray(resolvedErrors) ? resolvedErrors.join(', ') : resolvedErrors) || (hideHelperText ? '' : ' ');
        const locale = rest.locale || getBrowserLocale();
        const l10n = getL10nDef(locale);
        const { theme } = useStyling();
        const [selectedValue, setSelectedValue] = useState('');
        const [secondaryChecked, setSecondaryChecked] = useState(false);

        const [fileObjects, setFileObjects] = useState([]);

        const options = Array.isArray(rest.options)
            ? rest.options.map((option) => ({
                  ...option,
                  // Assign id for options that are references but have no id.
                  id: !isNil(option.id)
                      ? option.id
                      : option.reference && !isNil(option.reference.id)
                        ? option.reference.id
                        : undefined
              }))
            : [];

        const fixedOptions = rest.fixedOptions ? rest.fixedOptions : [];

        const getDateTimePickerSlotProps = () => {
            return {
                textField: {
                    size: size,
                    fullWidth: true,
                    error: isError,
                    helperText: errorText
                }
            };
        };

        const CODE_TO_SYMBOL = {
            USD: '$',
            GBP: '£',
            EUR: '€',
            INR: '₹'
        };
        const currencies = l10n.currencies;
        const filtering: KeyValueMap<string, { label: string; upper: string }> = useMemo(() => {
            return Object.keys(currencies).reduce(function (map, key) {
                map[key] = {
                    label: `${key} - ${currencies[key].name}`,
                    upper: `${key} - ${currencies[key].name}`.toUpperCase()
                };
                return map;
            }, {});
        }, [currencies]);

        if (rest.hidden) {
            return <React.Fragment />;
        } else if (component === 'Autocomplete') {
            const filterOptions = createFilterOptions<ReferenceOption>();

            return (
                <Autocomplete
                    fullWidth
                    disabled={disabled}
                    value={value}
                    size={size}
                    freeSolo={rest.allowFreeForm}
                    multiple={rest.multiple}
                    options={options}
                    limitTags={rest.limitTags || 2}
                    filterOptions={(options: any, params) => {
                        const filtered: ReferenceOption[] = filterOptions(options, params);

                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.label);

                        // Allow free form text in this field? Add this free form text as a first option.
                        if (rest.allowFreeForm && inputValue && !isExisting && !isAddEnabled) {
                            filtered.unshift({
                                id: '',
                                label: inputValue,
                                ref: {
                                    id: '',
                                    label: inputValue
                                }
                            });
                        } else if (
                            rest.allowFreeForm &&
                            inputValue !== '' &&
                            editInputValue &&
                            isAddEnabled &&
                            !isCustomEelementOpen
                        ) {
                            filtered.push({
                                id: inputValue,
                                label: `Add ${inputValue}`,
                                isAdd: true
                            });
                        }
                        return filtered;
                    }}
                    getOptionLabel={(option) => {
                        // if (option && (!option.hasOwnProperty('id') || !option.hasOwnProperty('label'))) {
                        //     console.error('Autocomplete options should be objects with id and label properties. Invalid option:', option);
                        //     return option;
                        // }
                        if (option) {
                            if (option.reference && option.reference.id) {
                                const found = find(options, { id: option.reference.id });
                                return found ? found.label : option.reference.id;
                            } else if (typeof option === 'object' && option.label) {
                                return option.label;
                            } else if (typeof option === 'string') {
                                const found = find(options, { id: option });
                                return found ? found.label : option;
                            }
                        }
                        return '';
                    }}
                    isOptionEqualToValue={(option, value) => {
                        // if (value && (value.hasOwnProperty('id') || value.hasOwnProperty('label'))) {
                        //     console.error('Autocomplete value should be a primitive, not object. Invalid value:', value);
                        //     return false;
                        // }
                        return option && value
                            ? value.id
                                ? option.id === value.id
                                : option.id === value || option.label === value
                            : false;
                    }}
                    disablePortal
                    renderInput={(inputParams) => (
                        <TextField
                            required={required}
                            disabled={disabled}
                            variant={variant}
                            // This is a small hack to get around label not aligning vertically if field is empty.
                            label={value ? rest.label : ''}
                            placeholder={value ? '' : rest.label}
                            error={isError}
                            helperText={errorText}
                            FormHelperTextProps={{ error: isError }}
                            InputProps={{
                                ...inputParams.InputProps,
                                // size: size,
                                endAdornment: (
                                    <React.Fragment>{Icon || inputParams.InputProps.endAdornment}</React.Fragment>
                                )
                            }}
                            onBlur={rest.onBlur}
                            {...inputParams}
                        />
                    )}
                    onChange={(e, newVal) => {
                        let val = rest.multiple
                            ? newVal && newVal.length
                                ? newVal
                                      .map((v) => {
                                          return v;
                                      })
                                      .filter((v) => {
                                          return v;
                                      })
                                : []
                            : newVal
                              ? newVal.id
                              : null;
                        if (newVal?.isAdd) {
                            val = null;
                        }
                        if (rest.allowFreeForm) {
                            val = newVal?.label || newVal;
                        }
                        commit(rest.name, val);
                    }}
                    renderOption={(props, option) => {
                        const { ...optionProps } = props;
                        return isAddEnabled && option?.isAdd ? (
                            <li
                                {...optionProps}
                                onMouseDown={() => {
                                    toggleOpen(true);
                                    rest.setSelectedValue(option?.id);
                                }}
                            >
                                {option.label} {option.key && `(${option.key})`}
                            </li>
                        ) : (
                            <li {...optionProps}>
                                {option.label} {option.key && `(${option.key})`}
                            </li>
                        );
                    }}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={typeof option === 'object' ? option.label : option}
                                {...getTagProps({ index })}
                                disabled={
                                    fixedOptions.indexOf(typeof option === 'object' ? option.label : option) !== -1
                                }
                            />
                        ))
                    }
                />
            );
        } else if (component === 'Chips') {
            return (
                <ChipOptionsRenderer
                    options={options}
                    value={value}
                    label={rest.label}
                    isOptionEqualToValue={isReferenceOptionEqualToValue}
                    onChange={(option) => {
                        commit(rest.name, option);
                    }}
                    containerSx={rest.containerSx}
                />
            );
        } else if (component === 'Switch') {
            return (
                <FormGroup sx={{ mt: 1, ml: 1 }}>
                    <FormControlLabel
                        disabled={disabled}
                        control={
                            <Switch
                                checked={value || false}
                                size={size}
                                required={required}
                                readOnly={readOnly}
                                disabled={disabled}
                            />
                        }
                        label={rest.label}
                        // checked={value}
                        onChange={(e, newVal) => {
                            commit(rest.name, newVal);
                        }}
                    />
                    {!rest.hideHelperText && (
                        <FormHelperText required={required} error={isError}>
                            {errorText}
                        </FormHelperText>
                    )}
                </FormGroup>
            );
        } else if (component === 'MetadataAutocomplete') {
            return (
                <MetadataAutocomplete
                    disabled={disabled}
                    required={required}
                    value={value}
                    inputLabel={rest.label}
                    hasErrors={isError}
                    errorText={errorText}
                    onChange={(name, newValue) => commit(rest.name, newValue)}
                    variant={variant}
                    size={size}
                    {...rest}
                />
            );
        } else if (component === 'ReferenceAutocomplete') {
            return (
                <ReferenceAutocomplete
                    name={rest.name}
                    entity={rest.entity}
                    disabled={disabled}
                    required={required}
                    value={value}
                    inputLabel={rest.label}
                    hasErrors={isError}
                    errorText={errorText}
                    onChange={(name, newValue) => commit(rest.name, newValue)}
                    variant={variant}
                    size={size}
                    {...rest}
                />
            );
        } else if (component === 'Date') {
            const PickerComponent = isMobile ? MobileDatePicker : DesktopDatePicker;
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={l10n.dateFns}>
                    <PickerComponent
                        label={rest.label}
                        disabled={disabled}
                        readOnly={readOnly}
                        views={rest.views}
                        shouldDisableDate={rest.shouldDisableDate}
                        value={value ? parse(value, API_DATE_FORMAT, new Date()) : null}
                        onChange={(newVal: Date, context: PickerChangeHandlerContext<string>) => {
                            if (!context.validationError) {
                                const valid = isValid(newVal);
                                commit(rest.name, newVal && valid ? format(newVal, API_DATE_FORMAT) : newVal);
                            }
                        }}
                        slotProps={getDateTimePickerSlotProps()}
                        minDate={rest.minDate}
                        maxDate={rest.maxDate}
                    />
                </LocalizationProvider>
            );
        } else if (component === 'Time') {
            return (
                <TimeInput
                    required={required}
                    disabled={disabled}
                    label={rest.label}
                    // value will be a string in API time format
                    value={value}
                    // newVal will be a string in API format
                    onChange={(newVal) => {
                        commit(rest.name, newVal);
                    }}
                    placeholder={DISPLAY_TIME_PLACEHOLDER}
                    variant={variant}
                    size={size}
                    error={errorText}
                />
            );
        } else if (component === 'Location') {
            return (
                <LocationsInput
                    // name={rest.name}
                    appendTimeZone={rest.appendTimeZone}
                    value={value}
                    required={required}
                    disabled={disabled}
                    hideLabel
                    inputLabel={rest.label}
                    hasErrors={isError}
                    errorText={errorText}
                    onChange={(name, newValue) => commit(rest.name, newValue)}
                    variant={variant}
                    defaultOptions={rest.defaultOptions}
                    allowAdditionalLocations={rest.allowAdditionalLocations}
                    restrictToCountry={rest.restrictToCountry}
                    {...rest}
                />
            );
        } else if (component === 'Timezone') {
            return (
                <TimezoneInput
                    // name={rest.name}
                    value={value}
                    required={required}
                    disabled={disabled}
                    inputLabel={rest.label}
                    hasErrors={isError}
                    errorText={errorText}
                    onChange={(e, newValue) => commit(rest.name, newValue)}
                    variant={variant}
                    {...rest}
                />
            );
        } else if (component === 'Radiogroup') {
            const handleRadioChange = (e, value) => {
                setSelectedValue(value);
                if (value === rest?.isCheckRequired) {
                    setSecondaryChecked(false);
                }
                commit(rest.name, value);
            };

            const handleSecondaryCheckChange = (e) => {
                setSecondaryChecked(e.target.checked);
            };
            return (
                <FormControl
                    disabled={disabled}
                    required={required}
                    className="_formControl"
                    variant={variant}
                    error={isError}
                    fullWidth
                    sx={{ width: '100%' }}
                    component="fieldset"
                >
                    <FormLabel disabled={disabled} required={required} component="legend">
                        {rest.label}
                    </FormLabel>
                    <RadioGroup
                        aria-label="options"
                        name={rest.name}
                        value={selectedValue || rest.defaultValue || value}
                        onChange={handleRadioChange}
                        row
                        sx={{ gap: 2 }}
                    >
                        {options?.length &&
                            options.map((opt) => (
                                <V gap={2} key={opt.id}>
                                    <FormControlLabel
                                        disabled={rest.isDisabled && rest.defaultValue !== opt.id}
                                        value={opt.id}
                                        control={<Radio />}
                                        label={
                                            <V>
                                                <Typography sx={{ fontSize: '16px' }}>{opt.label}</Typography>
                                                {opt.secondaryLabel && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        {opt.secondaryLabel}
                                                    </Typography>
                                                )}
                                            </V>
                                        }
                                    />

                                    {rest.isCheckRequired && opt.secondaryCheck && (
                                        <V
                                            sx={{
                                                marginTop: 1,
                                                paddingLeft: 4
                                            }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={secondaryChecked}
                                                        onChange={handleSecondaryCheckChange}
                                                        disabled={selectedValue === rest?.isCheckRequired}
                                                        sx={{ padding: '0 9px 9px 0' }}
                                                    />
                                                }
                                                label={
                                                    <V display="flex" flexDirection="column">
                                                        <Typography sx={{ fontSize: '16px' }}>
                                                            {opt.secondaryCheck}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {opt.secondaryCheckLabel}
                                                        </Typography>
                                                    </V>
                                                }
                                            />
                                        </V>
                                    )}
                                </V>
                            ))}
                    </RadioGroup>
                </FormControl>
            );
        } else if (component === 'TextArea') {
            const { startAdornment, endAdornment, minRows, maxRows, ...other } = rest;
            return (
                <TextField
                    disabled={disabled}
                    required={required}
                    value={value}
                    variant={variant}
                    fullWidth
                    multiline
                    hiddenLabel={!rest.label}
                    error={isError}
                    helperText={errorText}
                    FormHelperTextProps={{ error: isError }}
                    InputProps={{
                        startAdornment: startAdornment ? startAdornment : undefined,
                        endAdornment: endAdornment ? endAdornment : undefined,
                        // inputComponent: TextareaAutosize,
                        inputProps: {
                            placeholder: rest.placeholder,
                            minRows: minRows ? minRows : 3,
                            maxRows: maxRows ? maxRows : 3,
                            sx: { resize: 'vertical', overflowY: 'auto', minHeight: '20px', height: '100%' }
                        }
                    }}
                    onChange={(e) => {
                        commit(rest.name, e.target.value);
                    }}
                    onBlur={(e) => {
                        const newValue = e.target.value.trim();
                        commit(rest.name, newValue);
                    }}
                    {...other}
                />
            );
        } else if (component === 'PhoneNumber') {
            return (
                <MuiPhoneNumber
                    disabled={disabled}
                    required={required}
                    id={rest.id}
                    defaultCountry={'us'}
                    aria-describedby={rest.ariaDescribedBy}
                    variant={variant}
                    fullWidth
                    disableAreaCodes
                    countryCodeEditable={false}
                    autoFormat
                    label={rest.label}
                    error={isError}
                    helperText={errorText}
                    FormHelperTextProps={{ error: isError }}
                    value={value}
                    onChange={(value) => {
                        const asYouType = new AsYouType();
                        asYouType.input(value as string);
                        commit(
                            rest.name,
                            asYouType.getNumber() ? asYouType.getNumber().number : asYouType.isValid() ? value : null
                        );
                    }}
                    InputProps={{
                        size: 'small'
                    }}
                />
            );
        } else if (component === 'Image') {
            return <ImageWidget name={rest.name} dimensions={rest.dimensions} onChange={commit} {...rest} />;
        } else if (component === 'Avatar') {
            const avatar = value as search.Reference;
            return (
                <H sx={{ gap: 1, border: `1px solid ${isDarkMode ? '#666' : '#ccc'}`, borderRadius: 1, p: 1 }}>
                    {avatar ? (
                        <>
                            <RoundAvatar title={avatar.label} imageId={avatar.id} />
                            <Button
                                variant="text"
                                color="primary"
                                component="label"
                                onClick={() => commit(rest.name, undefined)}
                            >
                                <I18n token={'image.avatar.remove'} />
                            </Button>
                        </>
                    ) : (
                        <ImageWidget
                            name={rest.name}
                            onChange={commit}
                            dimensions={[320, 320]}
                            label={<I18n token={'image.avatar.add'} />}
                        />
                    )}
                </H>
            );
        } else if (component === 'Dropzone') {
            // All properties at: https://yuvaleros.github.io/material-ui-dropzone/
            // Keep in mind that we are using a fork that works with mui 5
            return (
                <V className="Dropzone_container" sx={{ gap: 1, marginBottom: 3 }}>
                    <Typography>{rest.label}</Typography>
                    <Dropzone
                        onChange={(res, saveFile) => {
                            if (rest.attachmentsToEntity) {
                                onChange(saveFile);
                            } else {
                                commit(rest.name, res.data.reference, res.data.records);
                            }
                        }}
                        filesLimit={rest.filesLimit}
                        initialFiles={rest.initialFiles}
                        maxFileSize={rest.maxFileSize}
                        acceptedFiles={rest.acceptedFiles}
                        saveFiles={rest.saveFiles}
                        dropzoneKey={rest.dropzoneKey}
                        dropzoneRef={rest.dropzoneRef}
                        dropzoneText={rest.dropzoneText}
                        showPreviews={rest.showPreviews}
                        showPreviewsInDropzone={rest.showPreviewsInDropzone}
                        uploadUrl={rest.uploadUrl}
                        disableDropzone={rest.disableDropzone}
                    />
                </V>
            );
        } else if (component === 'Number') {
            const { forcePositive, decimals = 0, useGrouping, ...other } = rest;
            return (
                <TextField
                    fullWidth
                    value={!isNil(value) ? value : ''}
                    disabled={disabled}
                    required={required}
                    error={isError}
                    helperText={errorText}
                    FormHelperTextProps={{ error: isError }}
                    onChange={(e) => {
                        const newNumber = formatNumber(e.target.value.trim(), {
                            decimals,
                            forcePositive,
                            locale,
                            useGrouping
                        });
                        commit(rest.name, newNumber);
                    }}
                    size={size}
                    variant={variant}
                    InputProps={{
                        inputProps: {
                            maxLength: rest.maxLength
                        }
                    }}
                    {...other}
                />
            );
        } else if (component === 'RichText') {
            return (
                <LexicaljsEditor
                    id={rest.name}
                    content={value}
                    readOnly={readOnly}
                    onChange={(newValue) => {
                        return commit(rest.name, newValue);
                    }}
                    {...rest}
                />
            );
        } else if (component === 'Money') {
            const { autoFocus, label, ...other } = rest;
            const currencies = l10n.currencies;
            const val = value.units ? value.units + value.nanos / NANOS : '';
            const materialUITextFieldProps = {
                label: label,
                multiline: false,
                maxRows: 0,
                variant: variant,
                autoFocus: autoFocus,
                fullWidth: true,
                size: size,
                error: isError,
                helperText: errorText
            };
            const { isPrefix, decimalSeparator, groupSeparator } = getCurrencyConfig(locale);
            const currency = value.currencyCode ? currencies[value.currencyCode] : undefined;
            return (
                <NumericFormat
                    disabled={disabled || !value.currencyCode}
                    readOnly={readOnly}
                    value={val}
                    prefix={isPrefix && currency ? currency.symbol : ''}
                    suffix={!isPrefix && currency ? currency.symbol : ''}
                    allowNegative={false}
                    decimalScale={currency ? currency.decimal_digits : 2}
                    thousandSeparator={groupSeparator}
                    decimalSeparator={decimalSeparator}
                    customInput={TextField}
                    onValueChange={(newValue /*, sourceInfo*/) => {
                        const units = Math.floor(newValue.floatValue);
                        const nanos = Math.round((newValue.floatValue % 1) * NANOS);
                        const newMoney: Money = {
                            currencyCode: value.currencyCode,
                            units: units,
                            nanos: nanos
                        };
                        commit(rest.name, newMoney);
                    }}
                    {...materialUITextFieldProps}
                />
            );
        } else if (component === 'Currency') {
            return (
                <Autocomplete
                    fullWidth
                    disableClearable={rest.disableClearable}
                    size={'small'}
                    disablePortal
                    value={value}
                    options={Object.keys(currencies)}
                    getOptionLabel={(option: string) => filtering[option]?.label}
                    filterOptions={(options: string[], params) => {
                        const { inputValue } = params;
                        const filtered: string[] = [];
                        if (inputValue) {
                            const upperCase = inputValue.toUpperCase();
                            Object.keys(filtering).forEach((key) => {
                                if (filtering[key].upper.match(upperCase)) {
                                    filtered.push(key);
                                }
                            });
                        } else {
                            filtered.push(...Object.keys(filtering));
                        }
                        return filtered;
                    }}
                    renderInput={(params) => <TextField {...params} label={rest.label} />}
                    onChange={(e, newValue: string) => {
                        commit(rest.name, newValue);
                    }}
                />
            );
        } else if (component === 'ToggleButtonGroup') {
            return (
                <ToggleButtonGroup
                    color={rest.color || 'primary'}
                    value={value}
                    exclusive={rest.exclusive || true}
                    sx={rest.sx}
                    onChange={(e, val) => {
                        commit(rest.name, val);
                    }}
                    {...rest}
                >
                    {rest.options.map((option) => {
                        return (
                            <ToggleButton key={option.id} value={option.id}>
                                {option.label}
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonGroup>
            );
        } else if (component === 'SectionTitle') {
            return <SectionTitle {...rest} />;
        } else if (component === 'Checkbox') {
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e, val) => {
                                commit(rest.name, val);
                            }}
                        />
                    }
                    checked={value}
                    label={rest.label}
                />
            );
        } else {
            const { startAdornment, endAdornment, value, ...other } = rest;

            return (
                <TextField
                    inputRef={ref}
                    fullWidth
                    defaultValue={value}
                    disabled={disabled}
                    required={required}
                    error={isError}
                    helperText={errorText}
                    onChange={(e) => commit(rest.name, e.target.value)}
                    autoFocus={other.autoFocus}
                    size={size}
                    variant={variant}
                    InputProps={{
                        startAdornment: startAdornment ? startAdornment : undefined,
                        endAdornment: endAdornment ? endAdornment : undefined,
                        inputProps: {
                            placeholder: rest.placeholder
                        }
                    }}
                    {...other}
                />
            );
        }
    }
);
