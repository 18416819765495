import { LoadingButton } from '@mui/lab';
import { Container, Paper } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import I18n from '../i18n/I18n';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import SessionRecording from '../nav/SessionRecording';
import { AuthFormHeader } from './AccountLinks';
import { useLocation } from 'react-router-dom';
import PolicyLink from './PolicyLink';
import { V } from '../Layout';

export default ({
    title,
    valid,
    email,
    setEmail,
    hasErrors,
    keyboardEventHandler,
    isBusy,
    onContinue,
    setValid,
    validator
}) => {
    const location = useLocation();
    const presetEmail = location.state?.email ?? location.state?.username;

    const emailRef = React.useRef<HTMLInputElement>(null);

    return (
        <Container key="login" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
            <V
                fill
                sx={{
                    gap: '8px',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Paper
                    sx={{
                        maxWidth: '400px',
                        p: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <FormContainer>
                        <FormItem>
                            <AuthFormHeader title={presetEmail ? <I18n token="dialog.createAccount" /> : title} />
                        </FormItem>
                        <FormItem>
                            <FormWidget
                                name="username"
                                label={<I18n token="account.form.email" />}
                                value={presetEmail ?? email}
                                errors={valid?.errors?.username}
                                hasErrors={hasErrors('username')}
                                onChange={React.useCallback(
                                    (field, value) => {
                                        const validation = validator.validate({ username: emailRef.current?.value });
                                        setValid({ errors: validation.errors, isValid: validation.isValid });
                                        setEmail(emailRef.current ? emailRef.current?.value : '');
                                    },
                                    [setEmail]
                                )}
                                onKeyUp={(e) => keyboardEventHandler(e, email)}
                                disabled={!!presetEmail}
                                ref={emailRef}
                            />
                        </FormItem>
                        <FormItem>
                            <LoadingButton
                                sx={{ width: '100%' }}
                                variant={'contained'}
                                loading={isBusy}
                                id="continueButton"
                                onClick={() => onContinue(email)}
                                disabled={!valid?.isValid || isBusy}
                            >
                                <I18n token={'dialog.continue'} />
                            </LoadingButton>
                        </FormItem>
                        <SessionRecording />
                    </FormContainer>
                </Paper>
                <PolicyLink />
            </V>
        </Container>
    );
};
